<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            md="4"
            sm="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>
          <!-- Search -->
          <b-col md="8" sm="12">
            <div class="d-flex align-items-center justify-content-end">
              <!-- filter model table filter propu ile eşleşir-->
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
              <excelExport
                header="SATIŞ MİKTARLARI"
                :fields="excel_fields"
                :fetch="fetchData"
                sheetName="SATIŞ MİKTARI"
                name="Satılan Paketler"
                :before-generate="startDownload"
                :before-finish="finishDownload"
              >
                <button class="btn btn-primary ml-1 mr-1" style="width:120px;">
                  <feather-icon icon="UploadIcon" /><span class="ml-1"
                    >Excel</span
                  >
                </button>
              </excelExport>
            </div>
          </b-col>
        </b-row>
        <b-row class="my-date-row">
          <b-col md="3" sm="12">
            <label for="">{{ $t("Start Date") }}</label>
            <flat-pickr
              v-model="start_date"
              class="form-control"
              :config="configStart"
              :placeholder="placeholder"
            />
          </b-col>
          <b-col md="3" sm="12">
            <label for="">{{ $t("End Date") }}</label>
            <flat-pickr
              v-model="end_date"
              class="form-control"
              :config="configEnd"
              :placeholder="placeholder"
            />
          </b-col>
          <b-col md="6" sm="12">
            <div class="demo-inline-spacing mt-1">
              <b-form-radio
                v-model="selectedPeriod"
                name="some-radio9"
                value="d"
                class="custom-control-primary"
              >
                {{ $t("Day") }}
              </b-form-radio>
              <b-form-radio
                v-model="selectedPeriod"
                name="some-radio9"
                value="w"
                class="custom-control-success"
              >
                {{ $t("Week") }}
              </b-form-radio>
              <b-form-radio
                v-model="selectedPeriod"
                name="some-radio9"
                value="m"
                class="custom-control-danger"
              >
                {{ $t("Month") }}
              </b-form-radio>

              <b-form-radio
                v-model="selectedPeriod"
                name="some-radio9"
                value="y"
                class="custom-control-info"
              >
                {{ $t("Year") }}
              </b-form-radio>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative"
        striped
        :per-page="perPage"
        :current-page="currentPage"
        :items="packagesSoldData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :empty-filtered-text="$t('There are no records matching your request!')"
        :sort-desc.sync="isSortDirDesc"
      >
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm=""
            class="d-flex align-items-right justify-content-right radius 0"
          >
            <small v-if="$i18n.locale === 'tr'" class="mt-1">
              içerisindeki {{ totalRows }} kayıttan {{ currentPage * 1 }}-{{
                currentPage * perPage
              }}
              arası ({{ currentPage }}.sayfa) gösteriliyor
            </small>
            <small v-if="$i18n.locale === 'en'" class="mt-1">
              showing {{ currentPage * 1 }}-{{ currentPage * perPage }} (page
              {{ currentPage }}) of {{ totalRows }} records</small
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius
              0 "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aling="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  VBPopover,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DataBase from "@/services/DataBase.js";
import Ripple from "vue-ripple-directive";
import excel from "vue-excel-export";
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import "flatpickr/dist/flatpickr.css";
import dateformat from "dateformat";
import excelExport from "@/components/vue-json-excel/JsonExcel.vue";

export default {
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,

    flatPickr,
    BFormRadio,
    excelExport,
  },

  data() {
    return {
      placeholder: "dd.mm.YYYY",
      currentPage: 1,
      totalRows: 1,
      isSortDirDesc: false,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,
      selectedPeriod: "w",
      filterOn: [],
      locale: this.$i18n.locale,

      start_date: "12.12.2021",
      end_date: "12.12.2021",

      configStart: {
        dateFormat: "d.m.Y",
        locale: Turkish,
        minDate: "01.01.1940",
        maxDate: "today",
      },

      configEnd: {
        dateFormat: "d.m.Y",
        locale: Turkish,
        // maxDate: "today",
        minDate: "01.01.1940",
      },

      tableColumns: [
        {
          key: "customer_product_sales_id",
          label: "Customer Product Sales Id",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "product_price_id",
          label: "Product Price Id",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "activity_id",
          label: "Activity Id",
          thClass: "d-none",
          tdClass: "d-none",
        },
        { key: "date_range", label: this.$t("Date Range"), variant: "success" },
        { key: "activity_name", label: this.$t("Activity Name") },
        { key: "description", label: this.$t("Package Name") },
        { key: "quantity_sold", label: this.$t("Quantity Sold") },
        { key: "session", label: this.$t("Session") },
        { key: "package_price", label: this.$t("Package Price") },
        { key: "total_price", label: this.$t("Total Price") },
      ],

      excel_fields: {
        "TARİH ARALIĞI": "date_range",
        "AKTİVİTE ADI": "activity_name",
        PAKET: "description",
        "SATILAN MİKTAR": "quantity_sold",
        "SEANS ADEDİ": "session",
        "PAKET FİYATI": "package_price",
        "TOPLAM FİYAT": "total_price",
      },
      packagesSoldData: [],
    };
  },
  computed: {},
  watch: {
    packagesSoldData(val) {
      this.totalRows = val.length;
    },

    start_date(val) {
      this.configEnd.minDate = val; //bitiş tarihininin başlangıç değeri en az başlangıç tarihi değerine eşit olabilir
      var prmStartDate = this.$functions.ConvertToEnglishDateFormat(val);
      var prmEndDate = this.$functions.ConvertToEnglishDateFormat(
        this.end_date
      );
      this.dataDoldur(
        this.companyBranchID,
        prmStartDate,
        prmEndDate,
        this.selectedPeriod
      );
    },
    end_date(val) {
      this.configStart.maxDate = val;
      var prmStartDate = this.$functions.ConvertToEnglishDateFormat(
        this.start_date
      );
      var prmEndDate = this.$functions.ConvertToEnglishDateFormat(val);
      this.dataDoldur(
        this.companyBranchID,
        prmStartDate,
        prmEndDate,
        this.selectedPeriod
      );
    },

    selectedPeriod(val) {
      var prmEndDate = this.$functions.ConvertToEnglishDateFormat(
        this.end_date
      );
      var prmStartDate = this.$functions.ConvertToEnglishDateFormat(
        this.start_date
      );
      this.dataDoldur(this.companyBranchID, prmStartDate, prmEndDate, val);
    },
  },
  mounted() {
    this.totalRows = this.packagesSoldData?.length;
  },

  methods: {
    async fetchData() {
      //   await  this.dataDoldur(this.companyBranchID, this.start_date,this.end_date)
      return await this.packagesSoldData;
    },

    startDownload() {},

    finishDownload() {},
    dataDoldur(company_branch_id, startDate, endDate, selectedPeriod) {
      this.$database.ReportService.packages_sold(
        company_branch_id,
        startDate,
        endDate,
        selectedPeriod
      ).then((res) => {
        if (res.IsSuccess === 1 || res.IsSuccess === true) {
          this.packagesSoldData = [];
          res.Result.map((el) => {
            let packagesSoldRow = {};
            packagesSoldRow.customer_product_sales_id =
              el.CustomerProductSalesId;
            packagesSoldRow.product_price_id = el.ProductPriceId;
            packagesSoldRow.activity_id = el.ActivityId;
            packagesSoldRow.date_range = el.DateRange;
            packagesSoldRow.activity_name = el.ActivityName;
            packagesSoldRow.description = el.Description;
            packagesSoldRow.quantity_sold = el.QuantitySold;
            packagesSoldRow.session = el.Session;
            packagesSoldRow.package_price = el.PackagePrice;
            packagesSoldRow.total_price = el.TotalPrice;
            this.packagesSoldData.push(packagesSoldRow);
          });
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    this.companyBranchID = this.$store.getters[
      "auth/userInfo"
    ].company_branch_id;

    this.start_date = this.moment()
      .add(-1, "month")
      .locale("tr")
      .format("L");

    this.end_date = this.moment()
      .add(1, "month")
      .locale("tr")
      .format("L");

    this.selectedPeriod = "w";
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/productListScoped.scss" scoped></style>
